import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import { ProductsContext } from "../ProductsProvider"

export const CartContext = React.createContext()

/**
 * Manages the shopping cart, which is persisted in local storage.
 * The cart and related methods are shared through context.
 */
const CartProvider = ({ children }) => {
  const { products } = useContext(ProductsContext)

  // Manually update the cart in localStorage when needed.
  const saveToLocalStorage = () =>
    typeof window !== "undefined"
      ? localStorage?.setItem("cart", JSON.stringify(cart)) || false
      : false

  // Load cart from localStorage, or initialise it to [].
  var cart = []

  const [dummyState, setDummyState] = useState(0)
  try {
    var cartJson = localStorage.getItem("cart")
    cart = JSON.parse(cartJson)
  } catch (err) {
    // console.error("unable to read localStorage", err)
    saveToLocalStorage()
  }
  if (!Array.isArray(cart)) cart = []

  const cartExists = (product) =>
    cart.find((i) => i.id === product.id && i.price.id === product.price.id)

  const cartAdd = (product, selectedPrice) => {
    const newproduct = { ...product }
    const price = selectedPrice ?? product.prices[0]
    newproduct.price = price
    //console.log("CartProvider cartAdd", product, "to cart", cart)
    const existing = cartExists(newproduct)
    if (existing) existing.quantity += 1
    else cart.push({ quantity: (newproduct.quantity || 0) + 1, ...newproduct })
    //console.log("updated cart", cart, "with", newproduct)
    // trigger state change
    setDummyState(dummyState + 1)
    saveToLocalStorage()
  }

  const cartRemove = (item) => {
    //console.log("CartProvider cartRemove", item, "from cart", cart)
    const existing = cartExists(item)
    if (existing) {
      if (existing.quantity > 1) {
        existing.quantity -= 1
        //console.log("cartRemove: Subtracting quanity")
      } else {
        //console.log("cartRemove: Doing a filter delete", existing)
        cart = cart.filter((i) => i !== existing)
      }
    } else {
      console.error("item", item, "does not exist in cart")
    }
    setDummyState(dummyState + 1)
    saveToLocalStorage()
  }

  const cartClear = () => {
    cart = []
    saveToLocalStorage()
  }

  const available = (id, quantity = 1) => {
    const product = products[id]
    if (!product) {
      console.error(`Sku with id ${id} not found`)
      return false
    } else if (!product.active) {
      return false
    } else if (product.inventory.type === "infinite") {
      return true
    } else if (product.inventory.type === "bucket") {
      return ["in_stock", "limited"].includes(product.inventory.type)
    } else if (product.inventory.type === "finite") {
      return product.inventory.quantity >= quantity
    } else {
      return false
    }
  }

  const [showMenu, setShowMenu] = useState(false)

  return (
    <CartContext.Provider
      value={{
        cart,
        available,
        cartAdd,
        cartRemove,
        cartClear,
        showMenu,
        setShowMenu,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

CartProvider.propTypes = {
  children: PropTypes.any.isRequired,
}

export default CartProvider
