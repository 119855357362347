import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

export const TrackContext = React.createContext()

const LOCAL_STORAGE_KEY = "userStateStorageMain_v1"

/**
 * SiteWide Generic KeyValue Local Storage
 * The store and related methods are shared through context.
 * Util functions
 */
const TrackProvider = ({ children }) => {
  /** Load store from local storage. Initialize if not present or incorrect. */
  const [contents, setContents] = useState(() => {
    return (
      JSON.parse(
        typeof window !== "undefined" && localStorage.getItem(LOCAL_STORAGE_KEY)
      ) || {}
    )
  })

  /** Save store to local storage after load and on update */
  useEffect(() => {
    try {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(contents))
    } catch (err) {
      console.error("TrackProvider.js", err)
    }
  }, [contents])

  /** Get value of key, or return defaultVal if key is not present. */
  function getStore(key, defaultVal) {
    if (contents.hasOwnProperty(key)) return contents[key]
    return defaultVal
  }

  /** set key to value */
  function setStore(key, value) {
    // console.log("Set", key, "to", value)
    setContents((state) => {
      return { ...state, [key]: value }
    })
    // console.log("Set After", key, "to", value)
  }

  /** Removes key */
  function removeStore(key) {
    setContents((state) => {
      let newState = {}
      Object.entries(state).map(([k, v]) => {
        if (key !== k) {
          newState[k] = v
        }
      })
      return newState
    })
  }

  const ctx = {
    contents,
    getStore,
    setStore,
    removeStore,
  }

  return (
    <TrackContext.Provider value={{ ...ctx }}>{children}</TrackContext.Provider>
  )
}

TrackProvider.propTypes = {
  children: PropTypes.any.isRequired,
}

export default TrackProvider
