import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

export const ProductsContext = React.createContext()

/**
 * Wrapper to give Provider access to product nodes.
 */
const ProductsProvider = ({ children }) => (
  <StaticQuery
    query={productsQuery}
    render={(data) => <Provider data={data}>{children}</Provider>}
  />
)

ProductsProvider.propTypes = {
  children: PropTypes.any.isRequired,
}

/**
  Shares product information and availability through context.
  Products are first loaded from Gatsby's GraphQL store and then updated with
  current information from Stripe.
*/
const Provider = ({ data, children }) => {
  /** Load product data from Gatsby store */
  const products = data.allProduct.edges.map((productEdge) => {
    productEdge.node.images = data.allFile.edges
      .filter(
        (fileEdge) =>
          fileEdge.node.relativeDirectory === `products/${productEdge.node.id}`
      )
      .map((fileEdge) => fileEdge.node.childImageSharp.fluid)
    var md = data.allMarkdownRemark.edges.find((md) => {
      return md.node.frontmatter.prod === productEdge.node.id
    })
    productEdge.node.description = md?.node.frontmatter.desc
    productEdge.node.materials = md?.node.html
    return productEdge.node
  })

  return (
    <ProductsContext.Provider
      value={{
        products,
        listProducts: (sort) => {
          const fn = sort || ((a, b) => b.created - a.created)
          return Object.values(products).sort(fn)
        },
      }}
    >
      {children}
    </ProductsContext.Provider>
  )
}

Provider.propTypes = {
  data: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
}

const productsQuery = graphql`
  {
    allProduct {
      edges {
        node {
          id
          name
          prices {
            id
            unit_amount
            metadata {
              ringSize
            }
          }
          metadata {
            collection
            type
          }
        }
      }
    }
    allFile(
      filter: { relativeDirectory: { glob: "products/prod_*" } }
      sort: { order: ASC, fields: name }
    ) {
      edges {
        node {
          name
          relativeDirectory
          childImageSharp {
            fluid(maxWidth: 900, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            desc
            prod
          }
          html
        }
      }
    }
  }
`

export default ProductsProvider
