import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useContext } from "react"
import SideMenu from "./SideMenu"
import { CartContext } from "../Cart/CartProvider"
import { FavContext } from "../FavProvider"
import "../font-awesome-4.7.0/css/font-awesome.min.css"

const Header = () => {
  const { cart, showMenu, setShowMenu } = useContext(CartContext)
  const { fav } = useContext(FavContext)

  const toggleMenu = () => setShowMenu(!showMenu)

  return (
    <>
      <header className="header">
        <div className="header_inner d-flex flex-row align-items-center justify-content-start">
          <div className="logo">
            <Link to="/">DIANNE ROWE</Link>
          </div>
          <nav className="main_nav">
            <ul>
              <li>
                <Link to="/">home</Link>
              </li>
              <li>
                <div className="dropdown">
                  <button className="dropbtn">
                    <Link to="/collection">collection</Link>
                  </button>
                  <div className="dropdown-content">
                    <br />
                    <Link to="/collection/rings">Rings</Link>
                    <Link to="/collection/decollete">Decollete</Link>
                    <Link to="/collection/earrings">Earrings</Link>
                    <Link to="/collection/homme">Homme</Link>
                    <Link to="/collection/bespoke">Bespoke</Link>
                    <Link to="/collection/bracelets">Bracelets</Link>
                  </div>
                </div>
              </li>
              <li>
                <Link to="/faq">FAQ</Link>
              </li>
              <li>
                <Link to="/about">story</Link>
              </li>
              <li>
                <Link to="/contact">contact</Link>
              </li>
            </ul>
          </nav>
          <div className="header_content ml-auto">
            <div className="shopping">
              <div className="headercartbutton" onClick={toggleMenu}>
                <div className="cart">
                  <img src={"/shopping-bag.svg"} alt="" />
                  <div className="cart_num_container">
                    <div className="cart_num_inner">
                      <div className="cart_num">
                        {cart.reduce((sum, item) => sum + item.quantity, 0)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a href="/wishlist">
                <div className="star">
                  <div className="starwrap">
                    <i
                      className="fa fa-heart-o"
                      aria-hidden="true"
                      style={{
                        fontSize: "18px",
                        color: "black",
                      }}
                    ></i>
                  </div>
                  <div className="star_num_container">
                    <div className="star_num_inner">
                      <div className="star_num">{fav.length}</div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div
            className="burger_container 
			d-flex flex-column 
			align-items-center 
			justify-content-around menu_mm"
            onClick={toggleMenu}
          >
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </header>
      <SideMenu showMenu={showMenu} toggleMenu={toggleMenu} />
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
