/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import ProductsProvider from "./ProductsProvider"
import CartProvider from "./Cart/CartProvider"
import TrackProvider from "./TrackProvider"
import FavProvider from "./FavProvider"
import Header from "./NavAndMenu/header"
import "./bootstrap4/bootstrap.min.css"
import "./main_styles.css"
import "./responsive.css"
import "./font-awesome-4.7.0/css/font-awesome.min.css"
import Helmet from "react-helmet"
import "animate.css/animate.compat.css"

const Layout = ({ children, classExtra }) => {
  const extraClasses = "super_container " + classExtra
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  if (typeof window !== "undefined") {
    eval(`
    window.dataLayer = window.dataLayer || []
    function gtag() {
      dataLayer.push(arguments)
    }
    gtag("js", new Date())
    
    gtag("config", "UA-55661835-1")
    `)
  }

  return (
    <TrackProvider>
      <ProductsProvider>
        <FavProvider>
          <CartProvider>
            <Helmet>
              <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=UA-55661835-1"
              ></script>
            </Helmet>
            <div className={extraClasses}>
              <Header siteTitle={data.site.siteMetadata.title} />
              {children}
              <footer className="footer">
                <div className="container">
                  <div className="row">
                    <div className="col text-center">
                      <div className="footer_textrow">
                        <div className="footer_locationtext">
                          L O C A T I O N
                          <div className="footerlocationbody">
                            <br />
                            Designed in The Swan Valley
                            <br />
                            Perth, Western Australia
                            <br />
                            <br /> Email: <br /> info@rowecollection.com.au
                          </div>
                        </div>
                        <div className="footer_openingtext">
                          O P E N I N G - H O U R S
                          <div className="footeropeningbody">
                            <br />
                            By appointment only
                          </div>
                        </div>
                        <div className="footer_openingtext">
                          H E L P
                          <div className="footerhelpbody">
                            <br />
                            <Link to="/shipping-returns">
                              Shipping & Returns
                            </Link>
                            <br />
                            <Link to="/privacy-policy">Privacy Policy</Link>
                            <br />
                            <Link to="/terms-conditions">
                              Terms & Conditions
                            </Link>
                            <br />
                            <Link to="/ringsizechart">Ring Size Chart</Link>
                          </div>
                        </div>
                      </div>
                      <nav className="footer_nav">
                        <ul>
                          <li>
                            <Link to="/">home</Link>
                          </li>
                          <li>
                            <Link to="/collection">collection</Link>
                          </li>
                          <li>
                            <Link to="/about">story</Link>
                          </li>
                          <li>
                            <Link to="/contact">contact</Link>
                          </li>
                        </ul>
                      </nav>
                      <div className="footer_social">
                        <ul>
                          <li>
                            <a href="https://www.instagram.com/rowecollection/">
                              <i
                                className="fa fa-instagram"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li>
                            <a href="https://www.facebook.com/rowecollection">
                              <i
                                className="fa fa-facebook"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </CartProvider>
        </FavProvider>
      </ProductsProvider>
    </TrackProvider>
  )
}

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
export default Layout
