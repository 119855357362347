import React, { useContext } from "react"
import PropTypes from "prop-types"
import { CartContext } from "./CartProvider"
import { Link } from "gatsby"
import slug from "slug"

const CartItem = ({ item }) => {
  const { cartRemove } = useContext(CartContext)

  return (
    <div key={item.price.id} style={{ display: "flex", margin: "1rem 0" }}>
      <Link to={`/collection/${slug(item.name)}`}>
        <img
          style={{
            width: 100,
            maxHeight: 100,
            objectFit: "contain",
            marginRight: "0.5rem",
          }}
          src={item?.images[0]?.src}
          alt={item?.name}
        />
      </Link>
      <div style={{ flexBasis: "100%" }}>
        <div style={{ fontWeight: "bold" }}>{item?.name}</div>
        <div>Qty: {item?.quantity}</div>
        {item?.price.metadata.ringSize ? (
          <>Ring size: {item?.price.metadata.ringSize} </>
        ) : (
          ""
        )}
        <div>
          <strong>${(item?.price.unit_amount / 100) * item?.quantity}</strong>
        </div>
      </div>
      <div>
        {/* <p>
              {JSON.stringify(item.price)}
              {JSON.stringify(item.quantity)}
              {JSON.stringify(item.id)}
          </p> */}
      </div>
      <button
        className="cartremovebutton"
        style={{
          height: 20,
          width: 20,
          backgroundColor: "transparent",
          border: "none",
          color: "#ff3131",
          cursor: "pointer",
        }}
        onClick={() => {
          cartRemove(item)
        }}
      >
        &times;
      </button>
    </div>
  )
}

export default CartItem
