import React, { useContext, useState } from "react"
import { CartContext } from "./CartProvider"
import { Link } from "gatsby"

const CartNoteForm = ({ callCheckout, children }) => {
  const [state, setState] = React.useState({})
  const { cart } = useContext(CartContext)

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&")
  }

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const cartItemReport = () =>
    cart
      .map((item) => {
        let type = item?.metadata?.type ? `${item.metadata.type}` : ``
        let line = `* ${item.name} (${type}) ✕ ${item.quantity} : $${
          item.price.unit_amount / 100
        }\n`
        line += item?.price?.metadata
          ? Object.entries(item.price.metadata)
              .filter(([_, v]) => v || false)
              .map(([k, v]) => `  + ${k}: ${v?.toString()}\n`)
              .join(",\n")
          : ``
        line += `  Product::Item => ${item.id}::${item.price.id}\n`
        return line
      })
      .join("----\n")

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        cartitems: cartItemReport(),
        ...state,
      }),
    })
      .then(() => {
        callCheckout()
      })
      .catch((error) => alert(error))
  }

  const [agree, setAgree] = useState(false)

  const checkboxHandler = () => {
    setAgree(!agree)
    console.log("Agreed")
  }

  return (
    <form
      name="checknotes"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="checknotes" />
      <input type="hidden" name="cartitems" />
      <label hidden>
        Don’t fill this out: <input name="bot-field" onChange={handleChange} />
      </label>
      <div className="form-group">
        <label>
          <br />
          <input
            type="text"
            name="Order notes"
            placeholder="Order notes"
            onChange={handleChange}
          />
        </label>
      </div>
      <div>
        <input
          type="checkbox"
          id="agree"
          onChange={checkboxHandler}
          className="checkbox"
        />
        <label htmlFor="agree">
          {" "}
          I agree to{" "}
          <Link to="/terms-conditions">
            <b>terms and conditions</b>
          </Link>
        </label>
      </div>
      <button disabled={!agree} className="favcombined wishlistadd" role="link">
        Checkout
      </button>
    </form>
  )
}

export default CartNoteForm
