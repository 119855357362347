import React, { useContext } from "react"
import { Link } from "gatsby"
import { CartContext } from "../Cart/CartProvider"
import Checkout from "../Cart/Checkout"
import CartItem from "../Cart/CartItem"
import { isMobile } from "react-device-detect"

const SideMenu = ({ showMenu, toggleMenu }) => {
  const myclass = showMenu
    ? "menu d-flex flex-column align-items-end justify-content-start text-right menu_mm trans_400 active"
    : "menu d-flex flex-column align-items-end justify-content-start text-right menu_mm trans_400"
  const { cart } = useContext(CartContext)
  //console.log("rendering SideMenu with Cart", cart)

  return (
    <div className={myclass}>
      <div className="menu_close_container">
        <div className="menu_close" onClick={toggleMenu}>
          <div></div>
          <div></div>
        </div>
      </div>
      <div className="logo menu_mm">
        <Link to="/" onClick={toggleMenu}>
          DIANNE ROWE
        </Link>
      </div>
      <nav className="menu_nav">
        <ul className="menu_mm">
          <li>
            <Link to="/">home</Link>
          </li>
          <li>
            <Link to="/collection">collection</Link>
          </li>
          {isMobile ? (
            <div className="burgersublinks">
              <Link to="/collection/rings">-rings</Link>
              <br />
              <Link to="/collection/decollete">-decollete</Link>
              <br />
              <Link to="/collection/earrings">-earrings</Link>
              <br />
              <Link to="/collection/homme">-homme</Link>
              <br />
              <Link to="/collection/bespoke">-bespoke</Link>
              <br />
              <Link to="/collection/bracelets">-bracelets</Link>
            </div>
          ) : (
            ""
          )}
          <li>
            <Link to="/faq">FAQ</Link>
          </li>
          <li>
            <Link to="/about">story</Link>
          </li>
          <li>
            <Link to="/contact">contact</Link>
          </li>
        </ul>
      </nav>
      {cart.length !== 0 ? (
        <div className="sidemenuscroll">
          <div
            style={{
              maxWidth: 400,
              width: "100%",
              zIndex: 1,
            }}
          >
            <br />
            <br />
            <div className="cartheader">CART</div>
            {cart.length === 0 && (
              <span>
                <div className="cartnoitems">No items in cart.</div>
              </span>
            )}
            {cart.map((item) => (
              <CartItem key={item.price.id} item={item} />
            ))}
            <Checkout/>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  )
}
export default SideMenu
