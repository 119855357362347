import React, { useState, useContext, useEffect } from "react"
import PropTypes from "prop-types"
import { ProductsContext } from "./ProductsProvider"

export const FavContext = React.createContext()

/**
 * Manages the shopping cart, which is persisted in local storage.
 * The cart and related methods are shared through context.
 */
const FavProvider = ({ children }) => {
  const { products } = useContext(ProductsContext)

  /** Load cart from local storage. Initialize if not present or incorrect. */
  const [contents, setContents] = useState(() => {
    var localFav =
      typeof window !== "undefined" && JSON.parse(localStorage.getItem("fav"))
    if (!localFav || !Array.isArray(localFav)) localFav = []
    return localFav
  })

  /** Save cart to local storage after load and on update */
  useEffect(() => {
    try {
      localStorage.setItem("fav", JSON.stringify(contents))
    } catch (err) {
      console.error("FavProvider.js", err)
    }
  }, [contents])

  /** An array representing the favorites in the form of [{id}] */
  const fav = contents.map(([id]) => {
    return [products[id]]
  })

  /** The number of items in the cart */
  const favcount = contents.reduce((sum, _) => sum + 1, 0)

  function hasfav(id) {
    return contents.findIndex((item) => item[0] === id) > -1
  }

  /** Sets quantity of item with `id` */
  function set(id) {
    const index = contents.findIndex((item) => item[0] === id)
    setContents((state) => {
      const newState = [...state]
      if (index !== -1) {
        newState[index] = [id]
      } else {
        newState.push([id])
      }
      return newState
    })
  }

  /** Increments item with `id` by `quantity`, which defaults to 0 */
  function addfav(id) {
    set(id)
  }

  /** Removes item with `id` */
  function remove(id) {
    setContents((state) => {
      const newState = state.map((item) => {
        if (item[0] === id) {
          return null
        }
        return item
      })
      return newState.filter((item) => item !== null)
    })
  }

  /** Toggles cart display, or sets to the boolean `force` if provided */

  const ctx = {
    contents,
    fav,
    addfav,
    set,
    remove,
    hasfav,
    favcount,
  }

  return (
    <FavContext.Provider value={{ ...ctx }}>{children}</FavContext.Provider>
  )
}

FavProvider.propTypes = {
  children: PropTypes.any.isRequired,
}

export default FavProvider
